import React,{useState, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import './meImage.css'
import Api, {Get, Fetch} from '../Api'
import {Etat, meChoice} from '../Shared/meGlobal'

export const ImageView = ({node, onNode, Message=null}) => {
    //alert(message)
    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])
    const [clicked, Clicked] = useState({n:0,x:0,y:0,now:new Date()})
    const [clips, Clips]= useState([])
    const [page,Page] = useState({title:'',src:''})

    const clipper = new meChoice('Clip')
    clipper.historizing = 'techusm1_data'

    useEffect(() => {
        Load(false)
    }, [node])

    useEffect(() => {

        if(!loaded) {
            //cropImage(page.path, 250, 250, 500, 500)
            if(node.id>0) {
                const url = `data/${node.entity}/${node.id}`//; console.log(`ImageView Get ${url}`) ;alert(`ImageView Get ${url}`)
                Get(url, Items, Load)   
            }             
        }

        onRefresh()

        if(items?.length>0) {
            Page({            
                src:items[0].Path.replace('./','https://api.tech2us.ma/'),
                title:items[0].Name
            })
        }
    }, [loaded])

    const onRefresh = () => {
        Clips([])

        clipper.setArea(`${node.entity}`,`${node.entity}Image`)
        // Get Clips
        var data = []
        Api.get(`data/Clip/${node.id}`)
        .then(response => {
            response.data.forEach(clip => {
                const position = parseCoord(clip.Coord)
                data.push({...clip, Position:position})
                //console.log(clip.Coord, position)
            })
            Clips(data)
            
            //Message(`${data.length} Clips are loaded !`)
        })
    }

    const parseCoord = (coord) => {
        const cs = coord.split(',')   // x,y,w,h
        if(cs.length===4) {
            const ca = clipper.area // image.getBoundingClientRect()
            //console.log(ca)
            if(ca && ca.right>0 && ca.bottom>0) {
                //alert(coord)
                let left =   Math.round(cs[0]*clipper.area.width/100+ca.left)+'px'
                let top  =   Math.round(cs[1]*clipper.area.height/100+ca.top)+'px'
                let width =  Math.round(cs[2]*clipper.area.width/100)+'px'
                let height = Math.round(cs[3]*clipper.area.height/100)+'px'
                return {left, top, width, height}
            }
        }
    }
    
    const onClick = (e) => {
        const cr = e.currentTarget.getBoundingClientRect()
        //console.log(cr,e) ;alert(`onClick (event x ${e.clientX}, y ${e.clientY})`)
        if(cr && cr.right>0 && cr.bottom>0) {
            let w=parseInt(cr.right-cr.left)
            let h=parseInt(cr.bottom-cr.top)
           
            let n = clicked.n
            let x = parseFloat((e.clientX-cr.left)*100/w)?.toFixed(2)||0
            let y = parseFloat((e.clientY-cr.top )*100/h)?.toFixed(2)||0
            const now = new Date()
    
            if(Math.abs(clicked.x-x)<3 && Math.abs(clicked.y-y)<3) {
                let ms=now.getTime()-clicked.now.getTime()
                if(ms<500) {
                    n=n+1
                    if(n>1) {  // 3 click !

                        //Fetch clicked
                        const data = {
                            Entity: clipper.category,
                            ParentId: node.id,
                            Coord : `${clicked.x},${clicked.y},20,5`
                        }
                        //alert(data.Coord)
                        Fetch('data', data, e.currentTarget)
                        .then(item => {
                            onNode && onNode({...node, parent:node.parent})
                        })
                        .catch(err=>{
                            console.log(err)
                            //alert('Add Clip ?')
                        })
        
                    }
                }
                else
                    n=0
            }
            else
                n=0
    
            Clicked({n,x,y,now})  
        }
    }

    const onMouseDown = (e) => {
        clipper.setArea(`${node.entity}`,`${node.entity}Image`)
        clipper.mouseDown(e)
    }

    const onMouseMove = (e) => {
        clipper.mouseMove(e)
    }

    const onMouseUp = (e) => {
        clipper.mouseUp(e)
    }

    const onRead = (e) => {
        if(node.id>1) {
            const data = {
                action: 'Content',
                parent: node.id
            }
            Fetch('recognize', data)
            .then(response => response.json())
            .then(data => {
                //console.log('data', data)
                const todo = data?.todo
                if(todo>0) {
                    Message && Message(`Reading ${todo} !`)
                    onRead(e)
                }
                else {
                    onNode && onNode({...node, parent:node.parent})
                }
            } )
    
        }
    }

    return (

items.length>0 ?
    <div className="Page" id={`${node.entity}`}>

        <img alt="Page" id={`${node.entity}Image`} 
            src={page.src} 
            title={page.title}
            onClick={onClick}
        /> 
{ clips?.length>0 && clips.map((clip, index) => (
    <button className="Clip" id={`${clipper.category}${clip.Id}`} style={{...clip.Position}} 
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
    
        
    >+</button>
    
))}

    <Button className='Icon' variant={"danger"}  
        style={{ top:0, left:0, width:'4rem' }}
        onClick={(e)=>onRead(e)}
        ><i className={"fa fa-expand"} style={{display: 'flex', justifyContent: 'center'}}>Read</i>
    </Button>    

</div>
:
    <h2>Cropping & Resizing Images</h2>
    )
}


/*
        onClick={(e) => {Etat(`Clip ${clip.Id} ${index}`)}}

style={{ ...clip.Position}}  
style={{left:'100px', top:'100px', width:'20%', height:'20%', position:'absolute'}}


                let coord = clips[c].Coord ?? ''
                if(coord?.length>0 && coord.includes(',') && !coord.includes('px')) {
                    clips[c].Coord = parseCoord(coord)
                }

*/