import React, { useState, useEffect } from 'react'
import {Collapse, Button} from 'react-bootstrap'
import './Setting.css'
import {ToNotify} from 'fast_inon'
import TableView from '../meTable/TableView'

const Settings = ({connected, search, role}) => {
    const [message, Message] = useState('')

    const [tab0, Tab0] = useState({entity:'Setting', id:1, parent:0})
    const [tab1, Tab1] = useState({entity:'Entitie', id:0, field:'', value:'', parent:0})
    const [tab2, Tab2] = useState({entity:'Field', id:0, field:'', value:'', parent:0})


    const [entities, Entities]= useState({Entitie:false, Field:false, Setting:false})

    const onCollapse = (e) => {

        Entities({...entities, [e.target.name]: !entities[e.target.name]})
        //console.log("setEntity", entities); alert(entities.Activity)
    }

    const onTab1 = (item) => {
        Tab1(item)
    }

    const onTab2 = (item) => {
        Tab2(item)
    }
    
    useEffect(()=>{

    },[tab1])
    
//let _margin="'3% 5% 2% 5%'"

return (
<>
<ToNotify 
message={message}
onClose={()=>{Message('')}}
/>

{ connected && role>2 ?

<div style={{position:"relative", width:"inherit"}}>          

    <div className='CollapseTab'>         
        <Button name="Entitie" onClick={onCollapse} className='CollapseButton'>
        Entities 
        </Button>   
        <Collapse in={entities.Entitie}>
            <div className='CollapseDiv'>
                <TableView node={tab0} onChange={onTab1} />
            </div>
        </Collapse>
    </div>

    <div className='CollapseTab'>         
        <Button name="Field" onClick={onCollapse} className='CollapseButton'>
        Fields 
        </Button>   
        <Collapse in={entities.Field}>
            <div className='CollapseDiv'>
                <TableView node={tab1} onChange={onTab2}/>
            </div>
        </Collapse>
    </div> 

</div>             

: <h5>Not Connected</h5> }

</>
        )
}

export  {Settings}
