import {Component} from 'react'
import Api from './Api'

export default class Global extends Component{

    constructor(props){
        super(props)

        this.store=null     

        this.headers = {            
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
        
        this.parseStorage()

        this.logo='https://digitalservices.ma/images/logo.jpg'

        this.apiUrl = Api.defaults.baseURL

        this.state = {items:[], loading:false, search:''}

        this.selected =''
        this.search=''
    }

    connected() {
        return this?.store?.connected
    }

    Search(value) {
        this.search=value
        //console.log(`Global.search ${this.search}`)
    }

    Selected(value) {
        this.selected=value
        //alert(this.selected)
    }

    async AddNote () {
        let ss = this.selected.split('-',)
        console.log("Add note : ",this.selected, ss)

        if(ss && ss.length>1) {
            fetch(`${this.apiUrl}note`,{
                method:'POST',
                headers:this.headers,
                body:JSON.stringify({
                    Text:'New',
                    SectionId:ss[1]
                })
            })
            .then(response => response.json())
            .then(
                (result)=>{
                    //this.LoadNotes()
                    return {message:result.message}
                },
                (error)=>{
                    return {message:error.message}
                }
            )
        }
        else
            return {message:'You have to select a section ...'}
    }

    toString(datetime){
        const meDate = (datetime) ? new Date(datetime) : new Date() ;
        return meDate.toISOString().split('T')[0];
    }

    //bind the context:
    parseStorage() {
        this.store = JSON.parse(localStorage.getItem(Api.defaults.storage))
        //console.log('parseStorage',this.store)
        if(this.connected())
        {
            this.headers = {
                Authorization: `Bearer ${this.store.token}`,
                ...this.headers                
            }
            //console.log('headers',this.headers)
        }
    }
    
    removeStorage() {
        //alert('remove localStorage')
        localStorage.removeItem(Api.defaults.storage)
        global.store=null
        window.location.reload()
    }

    async GET(relativeUrl, params=null) {
        this.state ?? this.setState({loading:true})
        let request=this.apiUrl+relativeUrl
        //console.log('request', {request, params})

        let response = await fetch(request, {
            method: (params===null) ? "GET" : "POST"
            ,headers: this.headers
            ,body: params   //  request with get/head method cannot have body
        })
        if(response.ok){
            let data = await response.json()
            this.state ?? this.setState({items:data, loading:false})
            //console.log('response', data)
            return data
        }
        else {            
            throw new Error(`GET : ${response.status} ?`)
        }
    }

    async GetItem (controller, id) {

        this.state ?? this.setState({loading:true})

        let request = `${this.apiUrl}${controller}/${id}`
        //  alert(request)
        let response = await fetch(request, {
            method: "GET",
            headers: this.headers
        })
        if(response.ok){
            let data = await response.json()
            this.state ?? this.setState({loading:false})
            //console.log('GetItem', data)
            return data
        }
        else {
            throw new Error(`GetItem : ${response.status} ?`)
        }
    }


    async DELETE(controller, id)
    {
        let request = `${this.apiUrl}${controller}`

        if(window.confirm('Are you sure to delete this item?')){
            let response = await fetch(request, {
                method:'DELETE',
                headers: this.headers,
                body:JSON.stringify({
                    Id: id
                })
            })
            //console.log('response', response)
            if(!response.ok)
                throw new Error(`DELETE : ${response.status} ?`)
        }
    }

    toDay = () => {
        const meDate = new Date();
        return meDate.toISOString().split('T')[0]
    }

    Delay = ms => new Promise(res => setTimeout(res, ms))



}

//export default Global