import React from 'react'
import {NavLink} from 'react-router-dom'
import {Navbar, Container} from 'react-bootstrap'

export const Navigation = (connected) => {


    const addNote = () => {
        alert('addNote')
        //global.AddNote()
    }

        return (
            
            <Container>

{ connected ?
    <Navbar>
        <Navbar.Collapse id="basic-navbar-nav" >
            <NavLink className="d-inline p-3 navbar-custom" to="/" onClick={addNote}>Cropping</NavLink>
            <NavLink className="d-inline p-3 navbar-custom" to="/detail">Detail</NavLink>
            <NavLink className="d-inline p-3 navbar-custom" to="/setting">Setting</NavLink>
        </Navbar.Collapse>
    </Navbar>
: null }
            </Container>

        )
}
