import React, {useState, useEffect} from 'react'
import { ImageView } from './meImage/ImageView'
import {meSplitter} from './Shared/meGlobal'
import TreeView from "./meTree/TreeView"
import TableView from './meTable/TableView'
import {ToNotify} from 'fast_inon'

const Home = ({global}) => {
  const [message, Message] = useState('')
  const [cell, Cell] = useState({entity:'', id:0, field:'', value:''})
  const [node, Node] = useState({entity:'', id:0, parent:0})

  const splitter = new meSplitter("Side", "HomeContainer")

  const [clip, Clip] = useState({entity:'Clip', id:0})

  const onNode = (item) => {
    //alert(`entity=${item.entity}, id=${item.id}`)
    
    Node(item)
  }

  const onCell = (item) => {
    //console.log(cell)
    Cell(item)
  }

  useEffect(()=>{
    if(node.entity==='Page'){
      Clip({...clip, id:node.id})
    }
    else {
      Clip({...clip, id:0})
    }

  },[node])

        return (

global.connected() ?

<div className="Home" id="HomeContainer" 
  onMouseDown={(e)=>{splitter.mouseDown(e)}} 
  onMouseMove={(e)=>{splitter.mouseMove(e)}} 
  onMouseUp={(e)=>{splitter.mouseUp(e)}}
>

  <div className="side vside" id="SideTimer" style={{width: '25%'}}>
    <TreeView request={'ocr/nodes'} onChange={onNode} Message={Message} />
    
  </div>
  
  <div className="side vside" id="SideImage" style={{width: '40%'}}>
    {
    node.entity==='Page' ?
      <ImageView node={node} onNode={onNode} Message={Message} />
    : 
      <TableView node={node} onChange={onCell} onNode={onNode} Message={Message} />
    }
  </div>

  <div className="side vside" id="SideDetail" style={{width: '35%'}}>
  { clip.id>0 ?
    <TableView node={clip} onChange={onCell} onNode={onNode} Message={Message} />
  : <h3>Detail</h3> }
  </div>

  <ToNotify 
    message={message}
    onClose={()=>{Message('')}}
  />

</div>
  
: <div className="Home">LogIn</div>    
        )
    
}

export default Home