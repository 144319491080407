import React from 'react';
import {Row, Col} from 'react-bootstrap'

const Footer = () => {
    return (
        <Row className="Footer">
            <Col sm={2}>
            <i key="FooterStatus" id="status"></i>
            </Col>
            <Col sm={6}>
            <i className="FooterEtat" key="FooterEtat" id="etat"></i>
            </Col>
            <Col sm={4}>
            <p>@ RAIS Digital Services 2016</p>
            </Col>
        </Row>
    )
}

export default Footer;