import React, {useState} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Global from './components/Global'
import Home from './components/Home'
import { Settings } from './components/Setting/Settings';

const global = new Global()

const App = () => {

  const [role, Role] = useState(3)
  
  //global.Delay(1000)

  return (

    <BrowserRouter>

    <Header global={global} onRole={Role} />

    <Routes>    
      <Route path='/' element={ <Home global={global} />} exact />
      <Route path='/setting' element={ <Settings connected={global.connected()} search={global.search} role={role} />} exact />
    </Routes>
      
    <Footer/>

    </BrowserRouter>
  )
}

export default App;
