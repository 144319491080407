import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export class CountryControl extends Component{
    constructor(props){
        super(props)
        this.state = {countries:[], loading:false, matches: window.matchMedia("(max-width: 768px)").matches}
        this.apiUrl=  this.props.apiUrl ?? 'https://api.tech2us.ma/setting/countries'

        this.itemId=this.props.itemId ?? 1
        this.readOnly = this.props.readOnly ?? false
    }
 
    componentDidMount(){
        //alert(this.apiUrl)
        this.setState({loading:true})
        fetch(this.apiUrl)
        .then(response => response.json())
        .then(data => {
            this.setState({countries:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
          })
    }

    componentWillUnmount() { 
        this.setState({countries:[]})        
    }

    render() {
 
        return (
            this.state.loading ? 
            <h5>Countries are loading...</h5> 
            :
            <Form.Group className='form-row'>    
                <Form.Control className='col-sm-12 text-center' onChange={this.props.onChange} as="select" name="CountryId" defaultValue={this.itemId} readOnly={this.readOnly} >
                    {this.state.countries.map(country => 
                        <option key={country.Id} value={country.Id}>{(country.Name+" "+country.Phone)}</option>
                    )}
                </Form.Control>
            </Form.Group>             
        ) 
    }
}
