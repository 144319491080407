import React, {useState} from 'react'
import {FormControl, Row, Col, Navbar} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {Navigation} from './Shared/Navigation'
import SignModal from './Sign/SignModal'

const Header = ({global}) => {
    const app = "OCR"
    const logo = global.logo
    const connected = global.connected()
    const [showin, Showin] = useState(false)

    const onSearch = (e) => {
        global.Search(e.target.value)
    }

    const Logout = (e) => {
        global.removeStorage()
    }

    const Login = (e) => {
        Showin(true)
        //window.open("https://digitalservices.ma?app=Crop")
    }

    //console.log('Header.global',global)
    return (
        
        <div className="Header">
            <Row className="navbar navbar-custom" expand="lg" style={{justifyContent:"center", display: "flex"}}>
                <Col sm={1}>
                    <NavLink to="/" >
                        <img className="Logo" src={logo} alt="Home" height="90px" padding="5px" />
                    </NavLink>
                </Col>
                <Col  sm={6}>
                    <Navigation connected={connected} />
                </Col>
                <Col  sm={1} style={{ textAlign: 'right' }}>
                    <i className="fa fa-search" style={{ fontSize:'1.5rem' }} ></i>
                </Col>
                <Col  sm={3}>
                    <FormControl 
                        className="d-inline p-2" 
                        style={{ textAlign: 'center', backgroundColor:'lightgray' }} 
                        name="Search" type="text" 
                        defaultValue={global.search}
                        onChange={onSearch}
                        placeholder="Search Items" />
                </Col>
                <Col  sm={1}>
                <Navbar >
                { connected ?
                    <NavLink className="d-inline p-2 navbar-custom" to="/" onClick={Logout} >Exit</NavLink>
                :
                    <NavLink className="d-inline p-2 navbar-custom" to="/" onClick={Login} >Login</NavLink>  
                }
                </Navbar>
                </Col>
            </Row>

<SignModal 
    app={app}
    show={showin}
    logo={logo}
    onHide={()=>{Showin(false)}}
/> 

            
        </div>
        
    )
}

export default Header;