import axios from 'axios';

const Api = axios.create({

  //baseURL: `http://localhost:2022/`,
  baseURL: `https://api.tech2us.ma/`,
  storage : '2US',
  headers : {            
    'Accept':'application/json',
    'Content-Type':'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('2US'))?.token}`,
  },
  domain: 'ocr.1ai.ma',
  
})


const  Get = (request, Items, Load=null, Message=null) => {
  //alert(request)
  Items([])
  Api.get(request)
  .then(response => {
      Items(response.data)
      //console.log('Api.Get', request, response, response.data); alert(request+' then')
      Load && Load(true)
  })
  .catch(error => {
      Message && Message(error)
      //console.error(error)
  })
}

const Cookie = (key) => {
  let value=''
  const cookies = decodeURIComponent(document.cookie).split(';')
  cookies.forEach(c => {
    c = c.trim(); //console.log('cookie= ',c)
    if (c.indexOf(`${key}=`) === 0) {
      value=c.substring(key.length+1, c.length) //; alert(`${key}= ${c} => ${value}`)
    }
  })
  return value
}

async function Fetch(request, data, object=null, method='POST') {

	if(!request.startsWith('https'))    
		request = Api.defaults.baseURL+request
  
	return new Promise(function (resolve, reject) {
		//alert(`${request}  ${data.Entity}`)
		fetch(request, {
			method: method,  
			headers: new Headers({
				'Authorization': Api.defaults.headers.Authorization,
				'Content-Type': "application/json"
			})
			,body: data && JSON.stringify(data) 
		}) 
		.then( async (response) => {
			//console.log("Response", request, method, data, response); alert(`Response ${response.status}`)
			if( [200,201,202].includes(response.status)) { 

				object && (object.style.borderColor ='green')
				resolve(response)
			}
			else {
				object && (object.style.borderColor = 'orange')
			}
		})
		.catch((error) => {
			//console.log("Error", error, data)
			object && (object.style.borderColor = 'red')
			//reject(error)			
		})
	})
}

const Delay = ms => new Promise(res => setTimeout(res, ms))

export default Api
export {Get, Cookie, Fetch, Delay}
